import React, { useState, useEffect } from 'react';
import ProtocolOverviewCard from './components/ProtocolOverviewCard';
import { ExternalLink } from 'lucide-react';

// const API_URL = "http://localhost:3000"
const API_URL = "https://coredefiorg-backend-production.up.railway.app"

const App = () => {
  const [protocols, setProtocols] = useState<any[]>([]);
  const [tvlData, setTvlData] = useState<any[]>([]);

  const fetchData = () => {
    fetch(`${API_URL}/protocol-data`)
      .then((response) => response.json())
      .then((data) => setProtocols(data))
      .catch((error) => console.error('Error fetching protocol data:', error));

    fetch(`${API_URL}/tvl-data`)
      .then((response) => response.json())
      .then((data) => setTvlData(data))
      .catch((error) => console.error('Error fetching TVL data:', error));
  };

  const sortedProtocols = React.useMemo(() => {
    if (!protocols.length || !tvlData.length) return [];

    return [...protocols].sort((a, b) => {
      const aTvl = tvlData.find((t) => t.protocol.toLowerCase() === a.name.toLowerCase().replace(" ", "_").replace(" ", "_"))?.totalTvl || 0;
      const bTvl = tvlData.find((t) => t.protocol.toLowerCase() === b.name.toLowerCase().replace(" ", "_").replace(" ", "_"))?.totalTvl || 0;
      return bTvl - aTvl;
    });
  }, [protocols, tvlData]);

  const externalLinks = [
    { name: "Bridge", url: "https://bridge.coredao.org/bridge" },
    { name: "Stake BTC/CORE", url: "https://stake.coredao.org/" },
    { name: "Core Ignition", url: "https://ignition.coredao.org/registration/code?code=53D86F" },
    { name: "Guides", url: "https://youtube.com/playlist?list=PLZzB4XB7DZSj7Ze1AA3b4N1nAtHqi0uhM&si=dP7MnhWG9C69T0TK" },
  ];

  const totalTvl = tvlData.reduce((sum, protocol) => sum + (protocol.totalTvl || 0), 0);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-orange-100 to-yellow-100">
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-12">
          <div className="flex justify-center items-center mb-6">
            <h2 className="text-4xl font-bold text-center text-orange-600">CoreDeFi.org</h2>
          </div>

          <div className="flex flex-wrap justify-center mb-8 gap-4">
            {externalLinks.map((link) => (
              <a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-sm bg-orange-500 hover:bg-orange-600 text-white font-medium py-2 px-4 rounded-full transition-colors"
              >
                {link.name}
                <ExternalLink size={14} className="ml-1" />
              </a>
            ))}
          </div>

          <h1 className="text-5xl sm:text-5xl md:text-[4rem] font-bold text-center text-orange-600">
            ${totalTvl.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          </h1>
          <h1 className="text-xl sm:text-2xl md:text-[2rem] mb-5 font-bold text-center text-orange-400">(DeFi TVL)</h1>
          <div className="mt-6 text-center">
            <a href="https://t.me/LeveredToTheMax" target="_blank" rel="noopener noreferrer" className="text-orange-600 hover:text-orange-700 underline">
              Want to add your protocol? Dm Max
            </a>
          </div>

          <h2 className='mt-10 text-3xl font-bold text-orange-700'>Protocols</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedProtocols.length > 0 ? (
              sortedProtocols.map((protocol) => {
                const protocolTvlData = tvlData.find((t) => {
                  const tvlProtocol = t.protocol.toLowerCase().toLowerCase()
                  const apiProtocol = protocol.name.toLowerCase().replace(" ", "_").replace(" ", "_")
                  console.log(tvlProtocol, apiProtocol)
                  return tvlProtocol === apiProtocol
                });
                return (
                  <ProtocolOverviewCard 
                    key={protocol.name} 
                    protocol={protocol} 
                    tvlData={protocolTvlData || { totalTvl: 0, breakdown: [] }} 
                  />
                );
              })
            ) : (
              <h3 className='mt-10 text-xl font-bold text-orange-700'>Loading...</h3>
            )}
          </div>
        </div>
      </main>

      <footer className="bg-orange-200 py-4 px-6 mt-12">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <div className="text-orange-700 text-center sm:text-left mb-2 sm:mb-0">
            Built by <a href="https://x.com/max_nlx" target="_blank" rel="noopener noreferrer" className="underline">@max_nlx</a> from <a href="https://nlx.trade" target="_blank" rel="noopener noreferrer" className="underline">nlx.trade</a>
          </div>
          <div className="flex items-center">
            <span className="text-orange-700 mr-2">Powered by</span>
            <img src="/api/placeholder/100/40" alt="IceCreamSwap Logo" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;